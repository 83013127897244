import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const SanitairPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Yves De Vrient`,
        `Loodgieter Yves De Vrient`,
        `De Vrient`,
        `Vrient`,
        `Loodgieter De Vrient`,
        `loodgieter`,
        `loodgieter Kruisem`,
        `loodgieter Zingem`,
        `Loodgieter Oudenaarde`,
        `merken`,
        `diensten`,
        `sanitair`,
      ]}
    />
    <div>
      <h1>Sanitair</h1>
      <p>
        U kan bij ons terecht voor alle sanitaire werken. Van afvoersystemen tot
        watervoorziening van zowel nieuwbouw als renovatie. Ook voor herstelling
        van uw kraanwerk, waterleiding en toiletten helpen we u graag. Wij
        leveren en plaatsen waterverzachters en regenwater installaties. Voor de
        sanitaire toestellen (kraanwerk, douches, baden...) kunt u vrijblijvend
        terecht in volgende toonzalen:
        <br />
        <a
          href="http://www.sax-sanitair.be"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          Sax (Oudenaarde)
        </a>
        <br />
        <a
          href="http://www.caluwaerts.be"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          Caluwaerts (Eke)
        </a>
        <br />
        <a
          href="http://www.vanmarcke.be"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            lineHeight: `1.75rem`,
            color: `#0a67b7`,
          }}
        >
          Van Marcke (Gent, Kortrijk)
        </a>
      </p>
    </div>
  </Layout>
)

export default SanitairPage
